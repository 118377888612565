import { Component, OnDestroy, OnInit } from '@angular/core';
import { startWith, Subject, takeUntil } from 'rxjs';
import { DeviceStateService } from '../services/device-state.service';

@Component({
  selector: 'app-create-account-from-ryn-page',
  templateUrl: './create-account-from-ryn.component.html',
  styleUrls: ['./create-account-from-ryn.component.scss']
})
export class CreateAccountFromRynComponent implements OnInit, OnDestroy {

	mobile : boolean = false;

	private ngUnsubscribe = new Subject<void>();

	constructor(
		private readonly deviceStateService: DeviceStateService
	) {
		this.deviceStateService.screenName = 'menu_title_create_account_from_ryn';
		this.mobile = deviceStateService.isMobileResolution();
	}

	ngOnInit(): void {

		this.deviceStateService.screenResized
			.pipe(
				startWith(this.mobile),
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe(isMobile => this.mobile = isMobile);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}

import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { combineLatest, startWith, Subject, take, takeUntil } from 'rxjs';
import { DeviceStateService } from '../services/device-state.service';
import { BankAccountInfo, EpaymentPreferences, KycInfo, PaymentProviderRecurringPaymentStatus, PropertyPayment, UserInfo, UserPaymentInfo, UserPaymentInfoBankAccountData, UserPaymentInfoProfileData } from '../models/payments';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from '../utils/app-constants';
import { SnotifyService } from 'ng-snotify';
import { BackendService } from '../services/backend.service';
import { LoadingService } from '../services/loading.service';
import { AuthService } from '../services/auth.service';
import { AddressData, InvoiceData, LandlordTenantData, UserData } from '../models/common';
import { AppUtils } from '../utils/app-utils';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { TranslateService } from '@ngx-translate/core';

const ibantools = require('ibantools');

interface DocumentUploadStatus {
    isDroppingFile: boolean;
    fileName: string;
    file: File | undefined;
}

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit, OnDestroy {

    appUtils: AppUtils;

    userData: UserData | undefined;
    tenantData: LandlordTenantData | undefined;
    userPaymentInfo : UserPaymentInfo | undefined;

	landlordEpaymentsConfigured : boolean = false;
    setupNeeded : boolean = false;
    bankAccountInfoSetupNeeded: boolean = false;

    linkedRecurringIds: string[] = [];
    
    //kycNeeded: boolean = false;
    //kycInProgress : boolean = false;

    //kycRefusedCode : string = '';
    //kycRefusedCodeFound : boolean = false;
    //kycRefusedCodeSeen : boolean = false;

    allowedPaymentMethods: "only_credit_card" | "only_mandate" | "both" | "none" = "none"

    sectionTabs = ['tab_payments_setup', 'tab_payments_list'];
    currentSection : number = 0;
	
    // PAYMENTS CONFIGURATION

    tenantNameSurname : string = "";
    curStep : number = 1;

    // Step 1
    public basicInfoForm = this.formBuilder.group({
		name: new FormControl('', [Validators.required]),
		surname: new FormControl('', [Validators.required]),
		dateOfBirth: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
		nationality: new FormControl('', [Validators.required]),
        countryOfResidence: new FormControl('', [Validators.required])
	});
    today = new Date();

    // Step 2
    public bankInfoForm = this.formBuilder.group({
		beneficiary: new FormControl('', [Validators.required]),
		iban: new FormControl('', [Validators.required])
	});


    defaultInvoiceData: InvoiceData = {};
    invoiceData: InvoiceData = {
		type: 'natural'
	};
    currentInvoiceData: InvoiceData = { ...this.defaultInvoiceData };

    // Step 3
    /*
    @ViewChild('fileFront', { static: true }) elUploadFileFront: ElementRef | undefined;
    @ViewChild('fileBack', { static: true }) elUploadFileBack: ElementRef | undefined;
    @ViewChild('filePdf', { static: true }) elUploadFilePdf: ElementRef | undefined;

    kycDocumentType = new FormControl('pics', [Validators.required]);

    documentUploadStatus : DocumentUploadStatus[] = [
        { isDroppingFile: false, fileName: "", file: undefined },
        { isDroppingFile: false, fileName: "", file: undefined }
    ];
    hasFileSizeError = false;
    */


    // PAYMENTS VIEW 
    
    tabs = ['com_all', 'com_open', 'com_paid', 'com_overdue', 'com_blocked', 'com_refusal'];
    currentPageIndex : number = 0;

    payments: PropertyPayment[] = [];
    filteredPayments : PropertyPayment[] = [];
    displayedColumns : string[] = ["status", "desc", "dueDate", "total", "paid", "action"];


	// Mobile
	mobile : boolean = false;

    isLoading : boolean = true;

    isBookingPaymentMissing: boolean = false;

	private ngUnsubscribe = new Subject<void>();


	constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
		private readonly location: Location,
		private readonly deviceStateService: DeviceStateService,
        private readonly formBuilder: FormBuilder,
        private readonly toastService: SnotifyService,
        private readonly backendService: BackendService,
        private readonly loadingService: LoadingService,
        private readonly authService: AuthService,
        private readonly gaService: GoogleAnalyticsService,
        private readonly translateService: TranslateService
	) {
		this.mobile = deviceStateService.isMobileResolution();

        this.appUtils = new AppUtils(translateService);
	}

	ngOnInit(): void {

        const completeSetup = this.route.snapshot.queryParamMap.get('completeSetup');

        this.deviceStateService.screenName = 'menu_title_your_payments';
		this.loadingService.show(this.translateService.instant('loadings.loading_loading'));


        combineLatest([
            this.backendService.loadBasicUserData(),
            this.backendService.loadTenantLandlordData(),
            this.backendService.getUserPaymentInfo(),
            this.backendService.getPayments()
        ])
            .pipe(
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(([userData, tenantData, info, payments]) => {

                this.loadSetupInfo(userData, tenantData, info);

                if(completeSetup === "true"){
                    this.setupNeeded = true

                    if(this.bankAccountInfoSetupNeeded){
                        this.curStep = 2
                    }
                    /*
                    if(!this.bankAccountInfoSetupNeeded && this.kycNeeded){
                        this.curStep = 3
                    }
                    */
                }
                
                this.payments = payments.sort((a, b) => {
                    if (a.dueDate === b.dueDate) {
                        const aStatus = this.getStatus(a);
                        const bStatus = this.getStatus(b);
                        if (aStatus === bStatus) {
                            const aName = this.getPaymentTitle(a);
                            const bName = this.getPaymentTitle(b);
                            if (aName === bName) { 
                                if (a.amount === b.amount) {
                                    return b.amountPaid - a.amountPaid;
                                }
                                return b.amount - a.amount;
                            }
                            return bName.localeCompare(aName);
                        }
                        return this.getStatusComparableNumber(b) - this.getStatusComparableNumber(a);
                    }
                    return b.dueDate - a.dueDate;
                });

                this.linkedRecurringIds = payments.map(p => p.linkedPeriodicPaymentId).filter((v, i, a) => a.indexOf(v) === i).filter(it=> it);

                this.linkedRecurringIds.forEach(id => {
                    this.backendService.getRecurringPayment(id).subscribe(recurringPayment => {
                        if(recurringPayment?.paymentProviderAssociation?.status === PaymentProviderRecurringPaymentStatus.ACTIVE || recurringPayment?.paymentProviderAssociation?.status === PaymentProviderRecurringPaymentStatus.AUTH_NEEDED){
                            this.payments = this.payments.map(p => {
                                if(p.linkedPeriodicPaymentId === id){
                                    p.recurringPaymentStatus = recurringPayment.paymentProviderAssociation?.status
                                }
                                return p;
                            })
                        }
                    });
                });

                this.filteredPayments = this.payments;

                this.setBookingPaymentMissing(this.payments);

                this.isLoading = false;
                this.loadingService.hide();
            });


		this.deviceStateService.screenResized
			.pipe(
				startWith(this.mobile),
				takeUntil(this.ngUnsubscribe)
			)
			.subscribe(isMobile => this.mobile = isMobile);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	backClick () {
		this.location.back();
	}

    onSectionTabClicked (indx : number) {
        this.currentSection = indx;
    }

    showPaymentsList(): boolean {
        //return (!this.setupNeeded && !this.kycInProgress) || (this.setupNeeded && this.currentSection === 1) || !this.landlordEpaymentsConfigured
        return !this.setupNeeded || (this.setupNeeded && this.currentSection === 1) || !this.landlordEpaymentsConfigured
    }
    /*
    showKycInProgress(): boolean {
        return !this.setupNeeded && this.kycInProgress
    }
    */

    showSetup(): boolean {
        return this.setupNeeded && this.landlordEpaymentsConfigured && this.currentSection === 0
    }

    // PAYMENTS CONFIGURATION

    prefillUserDataFromTenantData(userData: UserData){
        this.basicInfoForm.setValue({
            "name": userData.name || "",
            "surname": userData.surname || "",
            "dateOfBirth": !!userData.birthDate ? new Date(userData.birthDate) : "",
            "email": this.authService.loginEmail || "",
            "nationality": (!!userData.invoiceData.countryCode && userData.invoiceData.countryCode !== '') ? { alpha2Code: userData.invoiceData.countryCode } : "",
            "countryOfResidence": (!!userData.invoiceData.countryCode && userData.invoiceData.countryCode !== '') ? { alpha2Code: userData.invoiceData.countryCode } : ""
        });
    }

    prefillUserDataFromUserInfo(userInfo: UserInfo){
        this.basicInfoForm.setValue({
            "name": userInfo.data.firstName || "",
            "surname": userInfo.data.lastName || "",
            "dateOfBirth": !!userInfo.data.birthDayUtcSeconds ? new Date(userInfo.data.birthDayUtcSeconds * 1000) : "",
            "email": userInfo.data.email || "",
            "nationality": {alpha2Code: userInfo.data.nationality} || "",
            "countryOfResidence": {alpha2Code: userInfo.data.countryOfResidence } || ""
        });
    }

    prefillBankDataFromTenantData(tenantData: LandlordTenantData, userData: UserData){
        this.bankInfoForm.setValue({
            "beneficiary": tenantData.bankData?.beneficiary || (userData.name + " " + userData.surname),
            "iban": tenantData.bankData?.iban || ""
        });
    }

    prefillBankDataFromBankAccountInfo(bankAccountInfo: BankAccountInfo){
        this.bankInfoForm.setValue({
            "beneficiary": bankAccountInfo.data.benificiaryName || "",
            "iban": bankAccountInfo.data.iban || "",
        });
    }

    setUserDataFromPaymentInfo(userInfo: UserInfo){
        this.basicInfoForm.setValue({
            "name": userInfo.data.firstName || "",
            "surname": userInfo.data.lastName || "",
            "dateOfBirth": !!userInfo.data.birthDayUtcSeconds ? new Date(userInfo.data.birthDayUtcSeconds * 1000) : "",
            "email": userInfo.data.email || "",
            "nationality": (!!userInfo.data.nationality && userInfo.data.nationality !== '') ? { alpha2Code: userInfo.data.nationality } : "",
            "countryOfResidence": (!!userInfo.data.nationality && userInfo.data.nationality !== '') ? { alpha2Code: userInfo.data.countryOfResidence } : ""
        });
    }

    setInvoiceAndBankData(bankAccountInfo: BankAccountInfo, invoiceData: InvoiceData){
        
        this.bankInfoForm.setValue({
            "beneficiary": bankAccountInfo.data.benificiaryName || "",
            "iban": bankAccountInfo.data.iban || ""
        });

        if(!this.appUtils.isAddressComplete(invoiceData)){
            console.log(`invoice-data Found insufficient address`)

            invoiceData.address = bankAccountInfo.data.address?.address || "";
            invoiceData.houseNumber = bankAccountInfo.data.address?.houseNumber || "";
            invoiceData.postalCode = bankAccountInfo.data.address?.postalCode || "";
            invoiceData.city = bankAccountInfo.data.address?.city || "";
            invoiceData.region = bankAccountInfo.data.address?.region || "";
            invoiceData.regionCode = bankAccountInfo.data.address?.regionCode || "";
            invoiceData.country = bankAccountInfo.data.address?.country || "";
            invoiceData.countryCode = bankAccountInfo.data.address?.countryCode || "";

            if(this.appUtils.isAddressComplete(invoiceData)){
                console.log(`invoice-data Updating address from bank-account-info`)
                this.backendService.updateInvoiceData(invoiceData)
            }
            
            this.setInvoiceData(invoiceData)
        }
        
       
    }

    setInvoiceData(invoiceData: InvoiceData){
        this.invoiceData = invoiceData
        this.currentInvoiceData = this.invoiceData;
    }

    setPaymentPreferences(epaymentPreferences: EpaymentPreferences) {
        const mandateEnabled = epaymentPreferences.bankMandatePolicy && epaymentPreferences.bankMandatePolicy !== "none"
        const creditCardsEnabled = epaymentPreferences.creditCardPolicy && epaymentPreferences.creditCardPolicy !== "none"
        
        if(mandateEnabled && !creditCardsEnabled){
            this.allowedPaymentMethods = "only_mandate"
        }
        else if(creditCardsEnabled && !mandateEnabled){
            this.allowedPaymentMethods = "only_credit_card"
        }
        else if(creditCardsEnabled && mandateEnabled){
            this.allowedPaymentMethods = "both"
        }else{
            this.allowedPaymentMethods = "none"
        }
    }

    isUserInfoComplete(userInfo: UserInfo){
        if(this.allowedPaymentMethods === 'both' || this.allowedPaymentMethods === 'only_mandate'){
            return userInfo.status === 'created' && !!userInfo.mangopay && !!userInfo.mangopay.walletId && !!userInfo.data
        }else{
            return userInfo.status === 'created' 
        }
    }

    isBankAccountInfoComplete(bankAccountInfo: BankAccountInfo){
        return bankAccountInfo && bankAccountInfo.status === 'created' && !!bankAccountInfo.data

    }

    setBookingPaymentMissing(payments: PropertyPayment[]){
        payments
            .filter((payment) => !!payment.bookingId)
            .forEach((payment)=>{
                if(!this.isBookingPaymentMissing && payment.status === 'close'){
                    this.isBookingPaymentMissing = payments.filter((p) => !!p.bookingId && 
                        payment.bookingId === p.bookingId &&
                        p.id != payment.id && 
                        (p.status !== 'close' && p.status !== 'inactivate')).length > 0;
                }
            })
    }

    /*
    getKycStatus(kycInfo: KycInfo){
        if(kycInfo.status === 'active' || kycInfo.status === 'succeeded' || kycInfo.status === 'succeded'){
            return "complete"
        }
        if(kycInfo.status === 'created'){
            return "created"
        }
        if((kycInfo.status === 'failed' || kycInfo.status === 'refused') && kycInfo.errorReasonCode && !this.kycRefusedCodeSeen){

            this.kycRefusedCode = kycInfo.errorReasonCode as string;
            this.kycRefusedCodeFound = [
                "DOCUMENT_UNREADABLE",
                "DOCUMENT_NOT_ACCEPTED",
                "DOCUMENT_HAS_EXPIRED",
                "DOCUMENT_INCOMPLETE",
                "DOCUMENT_MISSING",
                "DOCUMENT_DO_NOT_MATCH_USER_DATA",
                "DOCUMENT_DO_NOT_MATCH_ACCOUNT_DATA",
                "DOCUMENT_FALSIFIED",
                "UNDERAGE_PERSON"
            ].includes(this.kycRefusedCode);

            return "failed"
        }

        return "failed"
    }
    */

    setupDeviceState(){
        //if ((this.setupNeeded || this.kycInProgress) && this.landlordEpaymentsConfigured) {
        if (this.setupNeeded && this.landlordEpaymentsConfigured) {
            this.deviceStateService.screenName = 'menu_title_payments_configuration';
        } else {
            this.deviceStateService.screenName = 'menu_title_your_payments';
        }
    }

    loadSetupInfo (userData: UserData, tenantData: LandlordTenantData, info: UserPaymentInfo, forceSetupCont?: boolean) {
        
        this.userData = userData;
        this.tenantData = tenantData;
        this.userPaymentInfo = info;

        this.tenantNameSurname = userData.name + " " + userData.surname;
        this.landlordEpaymentsConfigured = tenantData.landlordEpaymentsConfigured;

        this.setupNeeded = false;

        this.setPaymentPreferences(tenantData.epaymentPreferences)

        if(!this.isUserInfoComplete(info.userInfo)){
            
            this.setupNeeded = true
            //this.kycInProgress = false;

            this.prefillUserDataFromTenantData(userData)

            this.setupDeviceState()

            return
        }else{
            this.prefillUserDataFromUserInfo(info.userInfo)
        }

        if(!this.isBankAccountInfoComplete(info.bankAccountInfo)){

            if(this.allowedPaymentMethods === "both" || this.allowedPaymentMethods === "only_credit_card"){
                this.bankAccountInfoSetupNeeded = true
                this.setupNeeded = false
            }
            if(this.allowedPaymentMethods === "only_mandate"){
                this.setupNeeded = true
            }

            //this.kycInProgress = false;

            this.prefillBankDataFromTenantData(tenantData, userData)
            this.setInvoiceData(userData.invoiceData)

            this.setupDeviceState()

            if(forceSetupCont){
                this.setupNeeded = true
            }
            
            return
        }else{
            this.prefillBankDataFromBankAccountInfo(info.bankAccountInfo)
        }

        /*
        if(!info.kycInfo || info.kycInfo.status === "not_created"){

            if(this.allowedPaymentMethods === "both" || this.allowedPaymentMethods === "only_credit_card"){
                this.kycNeeded = true
                this.setupNeeded = false
            }
            if(this.allowedPaymentMethods === "only_mandate"){
                this.setupNeeded = true
            }

            this.kycInProgress = false;

            this.setupDeviceState()

            if(forceSetupCont){
                this.setupNeeded = true
            }
            
            return
        }
        */

        this.setUserDataFromPaymentInfo(info.userInfo)
        this.setInvoiceAndBankData(info.bankAccountInfo, userData.invoiceData)

        /*
        const kycStatus = this.getKycStatus(info.kycInfo)

        switch (kycStatus) {
            case "complete":
                this.setupNeeded = false;
                this.kycInProgress = false;
                break;
            case "created":
                this.setupNeeded = false;
                this.kycInProgress = true;
                break
            case "failed":
                this.setupNeeded = false;
                this.kycInProgress = true;
                break
        }
        */

        this.setupDeviceState()
    }

    // Post step 1
    private handleStep1 () {

        this.basicInfoForm.markAllAsTouched();

        if(!this.basicInfoForm.valid){
            if(!this.basicInfoForm.get("countryOfResidence")?.valid || !this.basicInfoForm.get("nationality")?.valid){
                this.toastService.error(
                    this.translateService.instant('toasts.toast_error_check_country_fields_desc'),
                    this.translateService.instant('toasts.toast_error_check_fields_title'),
                    AppConstants.TOAST_STD_CONFIG
                );
            }else{
                this.toastService.error(
                    this.translateService.instant('toasts.toast_error_check_fields_desc'),
                    this.translateService.instant('toasts.toast_error_check_fields_title'),
                    AppConstants.TOAST_STD_CONFIG
                );
            }
            return
        }

        this.loadingService.show(this.translateService.instant('loadings.loading_info_loading'));

        const data : UserPaymentInfoProfileData = {
            birthDayUtcSeconds: (this.basicInfoForm.get("dateOfBirth")?.value as Date).getTime() / 1000,
            countryOfResidence: this.basicInfoForm.get("countryOfResidence")?.value.alpha2Code,
            email: this.basicInfoForm.get("email")?.value,
            firstName: this.basicInfoForm.get("name")?.value,
            lastName: this.basicInfoForm.get("surname")?.value,
            nationality: this.basicInfoForm.get("nationality")?.value.alpha2Code
        }
        
        this.backendService.postPaymentsProfile(data)
            .then(res => {

                this.gaService.gtag('event', 'payments_setup_profile_called', {
                    'event_label': 'Payments setup profile called',
                    'event_category': 'payments'
                });
                if(this.allowedPaymentMethods === 'only_credit_card' || this.allowedPaymentMethods === 'none'){
                    this.loadSetupInfo(this.userData!, this.tenantData!, res);
                    this.router.navigate(['/payments']);
                }else {
                    this.curStep += 1;
                    this.loadSetupInfo(this.userData!, this.tenantData!, res, true);
                }
            })
            .catch(err => {

                // TODO: show error-specific message
                this.toastService.error(
                    this.translateService.instant('toasts.toast_error_payments_setup_request_desc'),
                    this.translateService.instant('toasts.toast_error_payments_setup_request_title'),
                    AppConstants.TOAST_STD_CONFIG
                );
            })
            .finally(() => {
                this.loadingService.hide();
            });
    }

    // Post step 2
    private handleStep2 () {
        
        this.bankInfoForm.markAllAsTouched();

        if (this.bankInfoForm.valid || !this.appUtils.isAddressComplete(this.currentInvoiceData)) {

            const iban = ibantools.electronicFormatIBAN(this.bankInfoForm.get("iban")?.value);

            const sepaCountriesNotEUR = ["BG", "DK", "GI", "HU", "IS", "LI", "NO", "PL", "RO", "SE", "CH", "GB"];
            
            if (this.currentInvoiceData.countryCode === "GB" || iban.startsWith("GB")) {

                this.toastService.error(
                    this.translateService.instant('toasts.toast_error_no_gb_support_desc'),
                    this.translateService.instant('toasts.toast_error_no_gb_support_title'),
                    AppConstants.TOAST_STD_CONFIG
                );

            } else if (!ibantools.isValidIBAN(iban)) {

                this.toastService.error(
                    this.translateService.instant('toasts.toast_error_iban_not_valid_desc'),
                    this.translateService.instant('toasts.toast_error_iban_not_valid_title'),
                    AppConstants.TOAST_STD_CONFIG
                );

            } else if (!ibantools.isSEPACountry(this.currentInvoiceData.countryCode || "") || sepaCountriesNotEUR.includes(this.currentInvoiceData.countryCode || "")) {

                this.toastService.error(
                    this.translateService.instant('toasts.toast_error_country_not_sepa_eur_desc'),
                    this.translateService.instant('toasts.toast_error_country_not_sepa_eur_title'),
                    AppConstants.TOAST_STD_CONFIG
                );

            } else {

                this.loadingService.show(this.translateService.instant('loadings.loading_info_loading'));

                const addressData : AddressData = {
                    address: this.currentInvoiceData.address,
                    houseNumber: this.currentInvoiceData.houseNumber,
                    postalCode: this.currentInvoiceData.postalCode,
                    city: this.currentInvoiceData.city,
                    region: this.currentInvoiceData.region,
                    regionCode: this.currentInvoiceData.regionCode,
                    country: this.currentInvoiceData.country,
                    countryCode: this.currentInvoiceData.countryCode
                }

                const data : UserPaymentInfoBankAccountData = {
                    "address": addressData,
                    "benificiaryName": this.bankInfoForm.get("beneficiary")?.value,
                    "iban": iban,
                    "type": "iban"
                }

                this.backendService.postPaymentsBankAccount(data)
                    .then(res => {
                        this.loadSetupInfo(this.userData!, this.tenantData!, res, true);
                        this.curStep += 1;

                        this.gaService.gtag('event', 'payments_setup_bank_called', {
                            'event_label': 'Payments setup bank called',
                            'event_category': 'payments'
                        });

                        this.toastService.success(
                            this.translateService.instant('toasts.toast_success_payments_setup_desc'),
                            this.translateService.instant('toasts.toast_success_payments_setup_title'),
                            {
                                ...AppConstants.TOAST_STD_CONFIG,
                                timeout: 8000
                            }
                        );
    
                        this.router.navigate(['/payments']);
                    })
                    .catch(err => {

                        if (!!err && !!err.error && !!err.error.message && err.error.message.indexOf("IBAN") >= 0) {
                            
                            this.toastService.error(
                                this.translateService.instant('toasts.toast_error_payments_setup_request_iban_desc'),
                                this.translateService.instant('toasts.toast_error_payments_setup_request_title'),
                                AppConstants.TOAST_STD_CONFIG
                            );
                        } else {

                            // TODO: show error-specific message
                            this.toastService.error(
                                this.translateService.instant('toasts.toast_error_payments_setup_request_desc'),
                                this.translateService.instant('toasts.toast_error_payments_setup_request_title'),
                                AppConstants.TOAST_STD_CONFIG
                            );
                        }
                    })
                    .finally(() => {
                        this.loadingService.hide();
                    });
            }

        } else {
            this.toastService.error(
                this.translateService.instant('toasts.toast_error_check_fields_desc'),
                this.translateService.instant('toasts.toast_error_check_fields_title'),
                AppConstants.TOAST_STD_CONFIG
            );
        }
    }

	backStepClick () {
		this.curStep -= 1;
	}

	nextStepClick () {
        if (this.curStep === 1) {
            this.handleStep1();
        } else if (this.curStep === 2) {
            this.handleStep2();
        }
	}

    skipStepClick(){
        this.router.navigate(['/payments']);
        this.loadSetupInfo(this.userData!, this.tenantData!, this.userPaymentInfo!);
    }

    onAddressUpdated(event: AddressData) {
		
        this.currentInvoiceData = { ...this.currentInvoiceData, ...event };
	}

    /*
    kycDocumentTypeChanged (value : any) {
        
        this.documentUploadStatus = [
            { isDroppingFile: false, fileName: "", file: undefined },
            { isDroppingFile: false, fileName: "", file: undefined }
        ];
    }

    uploadDocument (isFront : boolean) {
        if (isFront) {
            if (!!this.elUploadFileFront) {
                this.elUploadFileFront.nativeElement.click();
            }
        } else {
            if (!!this.elUploadFileBack) {
                this.elUploadFileBack.nativeElement.click();
            }
        }
    }

    uploadDocumentPdf () {
        if (!!this.elUploadFilePdf) {
            this.elUploadFilePdf.nativeElement.click();
        }
    }

    fileDraggingStatusUpdated(dragging: boolean, isFront : boolean) {

        if (isFront) {
            this.documentUploadStatus[0].isDroppingFile = dragging;
        } else {
            this.documentUploadStatus[1].isDroppingFile = dragging;
        }
	}

    fileDraggingStatusUpdatedPdf(dragging: boolean) {
        this.documentUploadStatus[0].isDroppingFile = dragging;
	}

    uploadFilesByClick(event: any, isFront : boolean) {
        const fileList = event.target.files;
		this.processFileList(fileList, isFront);
	}

    uploadFilesByClickPdf(event: any) {
        const fileList = event.target.files;
		this.processFilePdf(fileList);
	}

    uploadFilesByDrop(fileList: FileList, isFront : boolean) {
		this.processFileList(fileList, isFront);
	}

    uploadFilesByDropPdf(fileList: FileList) {
		this.processFilePdf(fileList);
	}

    private processFileList(fileList: FileList, isFront : boolean) {
		
        this.hasFileSizeError = false;

        if (fileList.length > 0) {
            const file : File = fileList[0];
            
            if (file.size > 15 * 1024 * 1024) {
                this.hasFileSizeError = true;
                return;
            }

            if (isFront) {
                this.documentUploadStatus[0].fileName = file.name;
                this.documentUploadStatus[0].file = file;
            } else {
                this.documentUploadStatus[1].fileName = file.name;
                this.documentUploadStatus[1].file = file;
            }
        }
	}

    private processFilePdf(fileList: FileList) {
		
        this.hasFileSizeError = false;

        if (fileList.length > 0) {
            const file : File = fileList[0];
            
            if (file.size > 15 * 1024 * 1024) {
                this.hasFileSizeError = true;
                return;
            }

            this.documentUploadStatus[0].fileName = file.name;
            this.documentUploadStatus[0].file = file;
        }
	}

	saveClick () {
        // KYC files

        if (this.documentUploadStatus[0].file && (this.kycDocumentType.value === 'pdf' || this.documentUploadStatus[1].file)) {

            this.loadingService.show(this.translateService.instant('loadings.loading_info_loading'));

            this.backendService.postPaymentsKYCFiles(this.documentUploadStatus[0].file, this.documentUploadStatus[1].file)
                .then(res => {

                    this.loadSetupInfo(this.userData!, this.tenantData!, res);
                    this.loadingService.hide();

                    this.toastService.success(
                        this.translateService.instant('toasts.toast_success_payments_setup_desc'),
                        this.translateService.instant('toasts.toast_success_payments_setup_title'),
                        {
                            ...AppConstants.TOAST_STD_CONFIG,
                            timeout: 8000
                        }
                    );

                    this.gaService.gtag('event', 'payments_setup_kyc_sent', {
                        'event_label': 'Payments setup kyc sent',
                        'event_category': 'payments'
                    });

                    this.router.navigate(['/payments']);
                    
                })
                .catch(err => {

                    // TODO: show error-specific message
                    this.toastService.error(
                        this.translateService.instant('toasts.toast_error_payments_setup_request_desc'),
                        this.translateService.instant('toasts.toast_error_payments_setup_request_title'),
                        AppConstants.TOAST_STD_CONFIG
                    );
                });

        } else {

            if (this.kycDocumentType.value === 'pics') {

                this.toastService.error(
                    this.translateService.instant('toasts.toast_error_add_kyc_photos_desc'),
                    this.translateService.instant('toasts.toast_error_add_kyc_photos_title'),
                    AppConstants.TOAST_STD_CONFIG
                );
            } else {
                // Pdf

                this.toastService.error(
                    this.translateService.instant('toasts.toast_error_add_kyc_pdf_desc'),
                    this.translateService.instant('toasts.toast_error_add_kyc_pdf_title'),
                    AppConstants.TOAST_STD_CONFIG
                );
            }
        }
	}

    restartKycProcessClick () {
        this.setupNeeded = true;
        this.kycInProgress = false;
        this.kycRefusedCodeSeen = true;
    }
    */


    // PAYMENTS VIEW

    onTabClicked (indx : number) {
        this.currentPageIndex = indx;

        if (this.currentPageIndex === 0) {
            
            this.filteredPayments = this.payments;

        } else if (this.currentPageIndex === 1) {
            
            this.filteredPayments = this.payments.filter(p => {
                const status = PropertyPayment.getStatusFromPayment(p);
                return status === 'open' || status === 'pending';
            });

        } else if (this.currentPageIndex === 2) {

            this.filteredPayments = this.payments.filter(p => {
                const status = PropertyPayment.getStatusFromPayment(p);
                return status === 'close' || status === 'succeeded';
            });

        } else if (this.currentPageIndex === 3) {

            this.filteredPayments = this.payments.filter(p => {
                const status = PropertyPayment.getStatusFromPayment(p);
                return status === 'overdue';
            });
            
        }  else if (this.currentPageIndex === 4) {

            this.filteredPayments = this.payments.filter(p => {
                const status = PropertyPayment.getStatusFromPayment(p);
                return status === 'blocked';
            });
            
        }  else if (this.currentPageIndex === 5) {

            this.filteredPayments = this.payments.filter(p => {
                const status = PropertyPayment.getStatusFromPayment(p);
                return status === 'inactivate';
            });
            
        }
    }

    getPaymentTitle (payment: PropertyPayment) : string {
        return this.appUtils.getPaymentTitle(payment);
    }

    getStatus (p: PropertyPayment) {
        return PropertyPayment.getStatusFromPayment(p);
    }

    getStatusComparableNumber (p: PropertyPayment) {

        const status = this.getStatus(p);

        return (status === 'overdue') ? 4 : (
            (status === 'open') ? 3 : (
                (status === 'pending') ? 2 : (
                    (status === 'failed' || status === 'succeeded') ? 1 : 0
                )
            )
        );
    }

    viewPayment (p: PropertyPayment) {
        this.router.navigate([p.id], { relativeTo: this.route });
    }

    goToPaymentsSettings () {
        this.router.navigate(['settings'], { relativeTo: this.route });
    }

    getPaymentIntentBlockedTooltip(): string {
        return this.translateService.instant('blocked_by_non_processed_payment_intent_tooltip');
    }

    askToBeContactedAsALegalPerson () {

        this.loadingService.show(this.translateService.instant('loadings.loading_loading'));

        this.backendService.contactUs("PAYMENTS SETUP", "Please contact me to set up the e-Payments (LEGAL PERSON)")
            .then(() => {
                this.loadingService.hide();
                this.location.back();
                this.toastService.success(
                    this.translateService.instant('toasts.toast_success_contact_us_desc'),
                    this.translateService.instant('toasts.toast_success_contact_us_title'),
                    {
                        ...AppConstants.TOAST_STD_CONFIG,
                        timeout: 5000
                    }
                );
            })
            .finally(() => {
                this.loadingService.hide();
            })
    }
}

export const environment = {
    firebase: {
        projectId: 'roommate-d6e73',
        appId: '1:677472613267:web:305b44fc4970385a002e66',
        databaseURL: 'https://roommate-d6e73.firebaseio.com',
        storageBucket: 'roommate-d6e73.appspot.com',
        locationId: 'us-central',
        apiKey: 'AIzaSyDj2hGRTJkGnE6t1zbnL24oUReaSdC8SOU',
        authDomain: 'roommate-d6e73.firebaseapp.com',
        messagingSenderId: '677472613267',
        measurementId: 'G-XJ73BMWY6L'
    },
    firebaseMessagingPublicKey: "BOCO0nM2tLO7IMwUvLmqMCe69CKDHquY7myZTLt0uF_uF89aGPfp3sHAKiqk8seqWkWZmvvn1yz-3yFkdv0aaFs",
      
    production: true,
    
    maps: {
        key: 'AIzaSyAEFDycZlfSp2fsbtcraGc8czOPOP9XpaE'
    },

    rentYourNest: {
        baseURL: 'https://rentyournest.com/o.php'
    },

    services: {
        backend: 'https://api.the-roommate.com/tenants/v1',
        backendPayments: 'https://api.the-roommate.com/payments/v1',
        backendEvents: 'https://api.the-roommate.com/events/v1',
        picsProxy: 'https://api.the-roommate.com/pics',
		socket: 'https://roommate-websockets-dot-roommate-d6e73.uc.r.appspot.com',
        fileManager: 'https://file-manager-dot-roommate-d6e73.uc.r.appspot.com/fm/v1'
    },

    mangopay: {
        mandateCreationRedirectUrl: 'https://places.the-roommate.com'
    }
};
